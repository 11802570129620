<template>
  <section>
    <title-section
      title="Detalles de la venta"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
    >
    </title-section>
    <sale-main-detail />
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SaleDetail',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    SaleMainDetail: () => import('@/components/Sales/Detail/SaleMainDetail.vue')
  },
  methods: {
    ...mapActions(['returnPrevView'])
  }
}
</script>

<style lang="scss" scoped>

</style>
